import { gql } from "@apollo/client";
import { MATCH_FIELDS } from "./employee";

export const FILTER_JOB_MANY = gql`
  query($skillIds: [ID], $unitId: ID) {
    jobMany(skillIds: $skillIds, unitId: $unitId) {
      name
      description
      salary
      competences {
        skill {
          name
        }
        skillId
        value
      }
      employees {
        firstName
        lastName
        photo
        _id
      }
      _id
    }
  }
`;

export const JOB_MANY = gql`
  query {
    jobMany {
      name
      description
      jobDescriptionId
      salary
      costs
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skillId
        skill {
          name
        }
        value
      }
      employees {
        firstName
        lastName
        photo
        _id
      }
      vacancyCount
      unitId
      revenues {
        year
        target
        actual
      }
      _id
    }
  }
`;

export const JOB_MANY_GROUP_BY_UNIT = gql`
  query {
    jobManyGroupByUnit {
      name
      count
      jobs {
        name
        description
        salary
        costs
        competences {
          skill {
            name
          }
          skillId
          value
        }
        employees {
          firstName
          lastName
          photo
          _id
        }
        otherEmployees {
          firstName
          lastName
          photo
          _id
        }
        vacancyCount
        unitId
        revenues {
          year
          target
          actual
        }
        _id
      }
    }
  }
`;

export const JOB_BY_ID = gql`
  query($_id: ID!) {
    jobById(_id: $_id) {
      name
      description
      jobDescriptionId
      salary
      costs
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skillId
        skill {
          name
        }
        value
      }
      employeeCount
      matches {
        ...MatchFields
      }
      unitId
      unit {
        name
      }
      revenues {
        year
        target
        actual
      }
      actualRevenue
      annualizedRevenue
      targetRevenue
      totalSalary
      required {
        employeeCount
        totalSalary
      }
      fileIds
      files {
        name
        url
        _id
      }
      _id
    }
  }
  ${MATCH_FIELDS}
`;

export const JOB_CREATE_ONE = gql`
  mutation(
    $name: String!
    $description: String
    $jobDescriptionId: ID
    $salary: Float!
    $competences: [CompetenceInput]
    $costs: Float
    $unitId: ID
  ) {
    jobCreateOne(
      input: {
        name: $name
        description: $description
        jobDescriptionId: $jobDescriptionId
        salary: $salary
        competences: $competences
        costs: $costs
        unitId: $unitId
      }
    ) {
      _id
      name
    }
  }
`;

export const JOB_UPDATE_BY_ID = gql`
  mutation(
    $_id: ID!
    $name: String
    $description: String
    $jobDescriptionId: ID
    $salary: Float
    $costs: Float
    $competences: [CompetenceInput]
    $unitId: ID
    $revenues: [RevenueInput]
    $fileIds: [ID]
  ) {
    jobUpdateById(
      input: {
        name: $name
        description: $description
        jobDescriptionId: $jobDescriptionId
        salary: $salary
        costs: $costs
        competences: $competences
        unitId: $unitId
        revenues: $revenues
        fileIds: $fileIds
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const JOB_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    jobRemoveById(_id: $_id) {
      name
    }
  }
`;

export const JOB_COUNT = gql`
  query {
    jobCount
  }
`;

export const JOB_COMPETENCES = gql`
  query {
    jobCompetences {
      _id
      name
      value
      color
    }
  }
`;
