import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { Requirement } from "../../types";
import { RequirementRow } from "./Row";

interface Props {
  onSubmit: (formData: any) => void;
  unitId?: string;
  data?: Array<Requirement>;
}

const RequirementFields = ({ onSubmit, data, unitId }: Props) => {
  const { formatMessage: f } = useIntl();

  const { register, control, handleSubmit, reset } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "requirements",
  });

  useEffect(() => {
    if (data) {
      reset({ requirements: data });
    }
  }, [data, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {f({ id: "target" })}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {f({ id: "actual" })}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {f({ id: "job.name" })}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {fields.map((item: any, index) => (
                  <RequirementRow
                    key={index}
                    index={index}
                    unitId={unitId}
                    item={item}
                    register={register}
                    control={control}
                    onRemove={remove}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="p-4 flex space-x-4">
        <span className="hidden sm:block shadow-sm rounded-md">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700 transition duration-150 ease-in-out"
            onClick={() =>
              append({
                quantity: 1,
                jobId: "",
              })
            }
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Hinzufügen
          </button>
        </span>
        <span className="hidden sm:block shadow-sm rounded-md">
          <button
            type="submit"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-coral-500 hover:bg-coral-400 focus:outline-none focus:shadow-outline-indigo focus:border-orange-600 active:bg-coral-600 transition duration-150 ease-in-out"
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            {f({ id: "actions.save" })}
          </button>
        </span>
      </div>
    </form>
  );
};

export default RequirementFields;
