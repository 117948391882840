import { useQuery } from "@apollo/client";
import type { Control, UseFormRegister } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";

import { EMPLOYEE_COUNT_BY_JOB_AND_UNIT } from "../../schema/employee";
import { Requirement } from "../../types";
import { JobSelect } from "../common/form";
import { Loading } from "../ui/Alerts";

interface Props {
  index: number;
  item: Requirement;
  unitId?: string;
  register: UseFormRegister<any>;
  control: Control;
  onRemove: (index: number) => void;
}

const RequirementRow = ({
  index,
  item,
  unitId,
  register,
  control,
  onRemove,
}: Props) => {
  const { formatMessage: f } = useIntl();

  const jobId = useWatch({
    control,
    name: `requirements.${index}.jobId`,
  });

  const { data: employeeData, loading, error } = useQuery(
    EMPLOYEE_COUNT_BY_JOB_AND_UNIT,
    {
      variables: {
        jobId: jobId || item.jobId,
        unitId: unitId,
      },
    }
  );

  if (error) {
    console.error("Error fetching employee count:", error);
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
        <input
          type="text"
          // @ts-ignore
          {...register(`requirements.${index}..quantity`, {
            required: true,
          })}
          defaultValue={item.quantity}
          className="w-16 mt-1 focus:ring-orange-500 focus:border-orange-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
        {loading ? <Loading /> : employeeData?.employeeCountByJobAndUnit}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
        <Controller
          control={control}
          // @ts-ignore
          name={`requirements.${index}.jobId`}
          render={({ field }) => (
            <JobSelect
              {...field}
              // onSelect={(jobId) => {
              //   console.log("Selected jobId:", jobId);
              //   setJobId(jobId as string);
              // }}
            />
          )}
          rules={{ required: true }}
          defaultValue={item.jobId}
        />
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
        <button
          className="inline-flex items-center"
          type="button"
          onClick={() => onRemove(index)}
        >
          <svg
            className="h-4 w-4 mr-2 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <span className="text-gray text-sm">
            {f({ id: "actions.delete" })}
          </span>
        </button>
      </td>
    </tr>
  );
};

export { RequirementRow };
